/* eslint-disable */

<template>
  <div>
    <div class="vx-row">
      <div class="vx-col lg:w-3/12 md:w-3/12 sm:w-5/5">
        <vx-card class="mb-base">
          <h3 class="font-bold mb-4">Step Completion</h3>
          <div :class="completion.step1 ? 'text-success' : ''" class="vx-row mb-4">
            <div class="vx-col w-1/5 p-3 align-middle flex">
              <feather-icon v-if="completion.step1" :icon="'CheckCircleIcon'" class="p-4 inline-flex rounded-full"></feather-icon>
              <feather-icon v-else :icon="'XCircleIcon'" class="p-4 inline-flex rounded-full"></feather-icon>
            </div>
            <div class="vx-col w-4/5 p-3">
              <p class="text-xl font-bold my-1 ubuntu-font">Step 1</p>
              <p class="text-xl font-bold my-1 ubuntu-font">Install the Service</p>
            </div>
          </div>
          <div :class="completion.step2 ? 'text-success' : ''" class="vx-row mb-4">
            <div class="vx-col w-1/5 p-3 align-middle flex">
              <feather-icon v-if="completion.step2" :icon="'CheckCircleIcon'" class="p-4 inline-flex rounded-full"></feather-icon>
              <feather-icon v-else :icon="'XCircleIcon'" class="p-4 inline-flex rounded-full"></feather-icon>
            </div>
            <div class="vx-col w-4/5 p-3">
              <p class="text-xl font-bold my-1 ubuntu-font">Step 2</p>
              <p class="text-xl font-bold my-1 ubuntu-font">Start the Service</p>
            </div>
          </div>
          <div :class="completion.step3 ? 'text-success' : ''" class="vx-row mb-4">
            <div class="vx-col w-1/5 p-3 align-middle flex">
              <feather-icon v-if="completion.step3" :icon="'CheckCircleIcon'" class="p-4 inline-flex rounded-full"></feather-icon>
              <feather-icon v-else :icon="'XCircleIcon'" class="p-4 inline-flex rounded-full"></feather-icon>
            </div>
            <div class="vx-col w-4/5 p-3">
              <p class="text-xl font-bold my-1 ubuntu-font">Step 3</p>
              <p class="text-xl font-bold my-1 ubuntu-font">Enable the Service</p>
            </div>
          </div>
          <div :class="completion.step4 ? 'text-success' : ''" class="vx-row mb-4">
            <div class="vx-col w-1/5 p-3 align-middle flex">
              <feather-icon v-if="completion.step4" :icon="'CheckCircleIcon'" class="p-4 inline-flex rounded-full"></feather-icon>
              <feather-icon v-else :icon="'XCircleIcon'" class="p-4 inline-flex rounded-full"></feather-icon>
            </div>
            <div class="vx-col w-4/5 p-3">
              <p class="text-xl font-bold my-1 ubuntu-font">Step 4</p>
              <p class="text-xl font-bold my-1 ubuntu-font">Verify the Service is Active</p>
            </div>
          </div>
        </vx-card>
      </div>
      <div class="vx-col lg:w-9/12 md:w-9/12 sm:w-5/5">
        <vx-card class="mb-base">
          <div class="fourth-sim pt-0">
            <div class="text-center">
              <p class="mb-4 font-bold underline text-5xl">CentOS Shell</p>
            </div>
            <div style="height: 35rem">
              <vue-command
                id="command_prompt"
                :commands="commands"
                :prompt="'[root@centos7]#'"
                class="rounded border-white border-solid"
                style="background-color: #111111"
                title="Command Prompt"
              />
            </div>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import Prism from 'vue-prism-component';
import VueCommand, { createStdout, createStderr } from 'vue-command';
import 'vue-command/dist/vue-command.css';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),

      completion: {
        step1: false,
        step2: false,
        step3: false,
        step4: false,
      },

      commands: {
        mema: () => createStdout('Is the best developer in the world'),
        help: () => createStdout('Available commands:<br>&nbsp;ping [args] [target]<br>'),
        yum: () => undefined,
        systemctl: () => undefined,
      },
    };
  },
  methods: {},
  components: {
    Prism,
    VueCommand,
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    //Disable Mark Button and Update Options in Renderer
    this.dataLocal.options.showMarkButton = false;
    this.$emit('simulation_update_options', this.dataLocal);

    this.commands.yum = (x) => {
      if (x._[1] !== 'install' || x._[2] === undefined) {
        return createStderr('Invalid Usage: yum install [package]');
      }

      if (x._[2] === 'httpd') {
        this.completion.step1 = true;
        return createStderr(
          'Loaded plugins: fastestmirror, refresh-packagekit<br>Setting up Instal Process<br>Load ing mirror speeds from cached hostfile<br>* base: centos.excellmedia. net<br>* epel: epel.scopesky.iq<br>* extras: centos.excellmedia . net<br>* updates: centos-hn.viettelidc.com.vn<br>Resolving Dependencies<br>There are unfinished transactions remaining. You might consider running yum-comp<br>lete-transaction first to finish them.',
        );
      }

      return createStdout('Package Not Found');
    };
    this.commands.systemctl = (x) => {
      const allowed_methods = ['start', 'enable', 'status'];

      if (!allowed_methods.includes(x._[1]) || x._[2] === undefined) {
        return createStderr('Invalid Usage: systemctl [action] [service]');
      }

      if (x._[2] !== 'httpd') {
        return createStderr(`Service ${x._[2]} Does Not Exist`);
      }

      if (x._[1] === 'start') {
        if (this.completion.step1 === false) {
          return createStderr(`Service ${x._[2]} Does Not Exist`);
        }
        this.completion.step2 = true;
        return createStderr(`Service ${x._[2]} Started`);
      }

      if (x._[1] === 'enable') {
        if (this.completion.step1 === false) {
          return createStderr(`Service ${x._[2]} Does Not Exist`);
        }
        if (this.completion.step2 === false) {
          return createStderr(`Service ${x._[2]} Is Not Started`);
        }
        this.completion.step3 = true;
        return createStderr(`Service ${x._[2]} Enabled`);
      }

      if (x._[1] === 'status') {
        if (this.completion.step1 === false) {
          return createStderr(`Service ${x._[2]} Does Not Exist`);
        }
        if (this.completion.step2 === false) {
          return createStderr(`Service ${x._[2]} Is Not Started`);
        }
        if (this.completion.step3 === false) {
          return createStderr(`Service ${x._[2]} Is Not Enabled`);
        }
        this.completion.step4 = true;

        setTimeout(() => {
          this.$emit('simulation_full_correct', 1);
        }, 750);

        return createStderr(
          'httpd.service - The Apache HTTP Server<br>Loaded: loaded (/usr/lib/systemd/system/httpd.service; enabled; vendor preset<br>Active: active (running) since Tue 2017-06-27 06:51:35 EDT; 14s ago<br>Docs: man:httpd(8)<br>man:apachectl(8)<br>Main PID: 17981 (httpd)<br>Status: "Total requests: 0; Current requests/sec: 0; Current traffic:<br>CGroup: /system.slice/httpd.service<br>O B/s<br>F17981 /usr/sbin/httpd -DFOREGROUND<br>-17982 /usr/sbin/httpd -DFOREGROUND<br>-17983 /usr/sbin/httpd -DFOREGROUND<br>-17984 /usr/sbin/httpd -DFOREGROUND<br>-17985 /usr/sbin/httpd -DFOREGROUND<br>-17986 /usr/sbin/httpd -DFOREGROUND<br>Jun 27 06:51:35 tecmint systemd[1]: Starting The Apache HTTP Server...<br>Jun 27 06:51:35 tecmint httpd[17981]: AH00558: httpd: Could not reliably determi<br>Jun 27 06:51:35 tecmint systemd[1]: Started The Apache HTTP Server.',
        );
      }

      return createStdout('Invalid Usage: systemctl [action] [service]');
    };
  },
};
</script>
